import React from 'react';
import { Link } from 'react-router-dom';
import bg2 from '../assets/images/re03.jpg';
import about from '../assets/images/background/land-kids.png';
import '../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import ScrollToTop from '../components/scroll-to-top';
import { services } from '../data/data';
import CarouselComponent from '../components/CarouselComponent';
import SideImage from '../components/SideImage';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { isMobile } from '../utils/helper';
import boy from '../assets/images/boy.png';
import bhands from '../assets/images/boy-hands.png';
import girl from '../assets/images/girl.png';

export default function IndexFunlandia() {
  // Define animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 },
  };

  // About Section InView
  const { ref: aboutRef, inView: aboutInView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Trigger when 50% of the section is in view
  });

  // Services Section InView
  const { ref: servicesRef, inView: servicesInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  // Party Section InView
  const { ref: partyRef, inView: partyInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div style={{ marginTop: isMobile ? '' : '' }}>
      <CarouselComponent />

      {/* About Section with Scroll-triggered Animation */}
      <section className="section pb-0" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <motion.div
                ref={aboutRef}
                initial="hidden"
                animate={aboutInView ? 'visible' : 'hidden'}
                variants={fadeInUp}
                transition={{ duration: 0.8 }}
                className="section-title mb-4"
              >
                <h4 className="title text-uppercase fw-bold mb-4">Every day is a special day</h4>
                <p className="text-muted mx-auto para-desc mb-0">
                  Come and play at FUNLANDIA Kid café! Have a wonderful time with your little ones
                  and help them make new friends.
                </p>
              </motion.div>
            </div>
          </div>

          <div className="row justify-content-center">
            <motion.div
              ref={aboutRef}
              initial="hidden"
              animate={aboutInView ? 'visible' : 'hidden'}
              variants={fadeInUp}
              transition={{ duration: 1, delay: 0.5 }}
              className="col-lg-8 mt-4 pt-2 text-center"
            >
              <p className="text-muted mx-auto para-desc mb-0">
                Our indoor playspace is designed for children ages 7 and under. Your child will
                connect with others their age and make unforgettable memories!
              </p>
            </motion.div>
          </div>
        </div>
        <div className="container-fluid p-0">
          {/* <div className="row"> */}
          <motion.div
            ref={aboutRef}
            initial="hidden"
            animate={aboutInView ? 'visible' : 'hidden'}
            variants={fadeInUp}
            transition={{ duration: 1, delay: 0.8 }}
            className="col-12"
          >
            <img
              src={about}
              className="img-fluid w-100"
              alt="Kids"
              style={{ width: window.innerWidth }}
            />
          </motion.div>
          {/* </div> */}
        </div>
      </section>

      {/* Services Section with Scroll-triggered Animation */}
      <section className="section bg-light">
        <div className="container">
          <motion.div
            ref={servicesRef}
            initial="hidden"
            animate={servicesInView ? 'visible' : 'hidden'}
            variants={fadeInUp}
            transition={{ duration: 0.8 }}
            className="row justify-content-center"
          >
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title text-uppercase fw-bold mb-4">Welcome!</h4>
                <h6 className="fw-bold mb-4">Here's what to know before you arrive</h6>
                <p className="text-muted mx-auto para-desc mb-0"></p>
              </div>
            </div>
          </motion.div>

          <motion.div
            ref={servicesRef}
            initial="hidden"
            animate={servicesInView ? 'visible' : 'hidden'}
            variants={fadeInUp}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="row justify-content-center"
          >
            {services.map((item, index) => (
              <div
                className={`col-lg-4 col-md-6 col-12 mt-4 ${
                  index === 1 ? 'custom-margin-top' : ''
                }`}
                key={index}
              >
                <div className="funlandia-service text-center p-2">
                  <div className="content mt-4">
                    <h4 className="title">{item.title}</h4>
                    <p
                      className="text-muted mt-3 mb-0"
                      dangerouslySetInnerHTML={{
                        __html: item.desc.replace(/\n/g, '<br />'),
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Side Images */}
      <SideImage
        imagePosition="right"
        imageUrl={bg2}
        smallImage={boy}
        imageWidth={50}
        imageHeight={80}
        title="Playtime Schedule"
        description={`Monday - Friday: 9:00 AM - 8:00 PM<br />
        Saturday: 9:00 AM - 4:00 PM<br />
        Sunday: Only for private play and birthday parties<br />`}
      />

      <SideImage
        imagePosition="left"
        imageUrl={bg2}
        smallImage={bhands}
        imageWidth={50}
        imageHeight={80}
        title="Safety Guidelines"
        description="Supervision: All children must be supervised by an adult at all times.<br />
        Health & Safety: Hand sanitizers are available throughout the facility. Please ensure your child washes their hands before and after play.<br />
        Footwear: Socks are required in the play area. No shoes or bare feet allowed."
      />

      <SideImage
        imagePosition="right"
        imageUrl={bg2}
        imageWidth={60}
        imageHeight={60}
        smallImage={girl}
        title="Special Treats"
        description="Cafe: Relax with a coffee while your kids play! Our on-site cafe offers a variety of snacks and beverages.<br />
        Free Wi-Fi: Stay connected while your kids enjoy their playtime.<br />
        Parent Lounge: Comfortable seating and charging stations available for parents."
      />

      {/* Party Section with Scroll-triggered Animation */}
      <motion.section
        ref={partyRef}
        initial="hidden"
        animate={partyInView ? 'visible' : 'hidden'}
        variants={fadeInUp}
        transition={{ duration: 1 }}
        className="section jarallax"
      >
        <div className="bg-overlay bg-overlay-white opa"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center">
              <div className="section-title mb-2">
                <h4 className="title text-uppercase fw-bold mb-4">Want to organize a party</h4>
                <h6>Have your child's next special party with us!</h6>
                <p className="mx-auto about-funlandia"></p>
                <Link
                  to="reservation"
                  className="btn btn-custom mouse-down mt-3"
                  whileHover={{ scale: 1.1 }}
                >
                  Make a Reservation
                </Link>
              </div>
            </div>
          </div>
        </div>
      </motion.section>

      <ScrollToTop />
    </div>
  );
}
