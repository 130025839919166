import React from 'react';
import { motion } from 'framer-motion';
import { FaClock } from 'react-icons/fa';
import paper from '../assets/images/background/watercolor-paper-texture.jpg';

import { isMobile } from '../utils/helper';

export default function HoursAndPricing() {
  const titleAnimation = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div
      style={{
        paddingTop: isMobile ? '20%' : '10%',
        paddingBottom: '5%',
        userSelect: 'none',
      }}
    >
      <section className="section" id="price">
        <div className="container">
          {/* Title Section - Keeping original style */}
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <motion.h4
                  className="title mb-4"
                  initial="hidden"
                  animate="visible"
                  variants={titleAnimation}
                  transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                  Hours & Prices
                </motion.h4>
                <motion.p
                  className="text-muted mx-auto para-desc mb-0"
                  initial="hidden"
                  animate="visible"
                  variants={titleAnimation}
                  transition={{ duration: 0.8, ease: 'easeInOut' }}
                >
                  A place for kids to play and parents to relax!
                </motion.p>
              </div>
            </div>
          </div>
          {/* Hours Section */}
          <div className="hours-section mt-5">
            <div className="hours-header text-center">
              <FaClock size={40} color="#ff6f61" />
              <h3 className="hours-title">Operating Hours</h3>
            </div>
            <div className="hours-details mt-4">
              <div className="hours-item">
                <p>
                  <strong>Monday - Friday:</strong> 9:00 am - 8:00 pm
                </p>
              </div>
              <div className="hours-item">
                <p>
                  <strong>Saturday:</strong> 9:00 am - 4:00 pm
                </p>
              </div>
              <div className="hours-item ">
                <p>
                  <strong>Sunday:</strong> Only for private play and birthday parties
                </p>
              </div>
            </div>
            <div className="asterisk-container">
              <p className="asterisk-text">*Hours are subject to change.</p>
            </div>
          </div>
          <div className="hours-pricing-section" style={{ backgroundImage: `url(${paper})` }}>
            {/* One Day Section */}
            <div className="pricing-category">
              <h3 className="category-title">Day Passes</h3>
              <div className="row pricing-row">
                {/* 0-8 Months */}
                <div className="col-lg-3 col-md-6 mt-4 pt-2">
                  <div className="pricing-card">
                    <div className="front">
                      <h5>0-8 Months</h5>
                      <p>Free</p>
                      <p style={{ fontSize: 14 }}>*with sibling</p>
                    </div>
                    <div className="back">
                      <p>Babies enter for free with sibling!</p>
                    </div>
                  </div>
                </div>

                {/* Weekday Admission */}
                <div className="col-lg-3 col-md-6 mt-4 pt-2">
                  <div className="pricing-card">
                    <div className="front">
                      <h5>Weekday Admission</h5>
                      <p>$17</p>
                      <p style={{ fontSize: 14 }}>*Bring your sibling for just $12!</p>
                    </div>
                    <div className="back">
                      <p>Bring your sibling for just $12!</p>
                      {/* <p>Perfect for weekday fun!</p> */}
                    </div>
                  </div>
                </div>

                {/* Weekend Admission */}
                <div className="col-lg-3 col-md-6 mt-4 pt-2">
                  <div className="pricing-card">
                    <div className="front">
                      <h5>Weekend Admission (Friday - Saturday)</h5>
                      {/* <h6>(Friday - Saturday)</h6> */}
                      <p>$19</p>
                      <p style={{ fontSize: 14 }}>*Bring your sibling for just $14!</p>
                    </div>
                    <div className="back">
                      {/* <p>Make weekends special!</p> */}
                      <p>Bring your sibling for just $14!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Passes Section */}
            <div className="pricing-category">
              <h3 className="category-title">Unlimited Fun</h3>
              <div className="row pricing-row">
                {/* 10 Admissions */}
                <div className="col-lg-3 col-md-6 mt-4 pt-2 kd-al">
                  <div className="pricing-card">
                    <div className="front">
                      <h5>
                        10 Admissions
                        <br /> (Any-day)*
                      </h5>
                      <p>$150</p>
                    </div>
                    <div className="back">
                      <p>10 plays, any day!</p>
                    </div>
                  </div>
                </div>

                {/* 15 Admissions */}
                <div className="col-lg-3 col-md-6 mt-4 pt-2">
                  <div className="pricing-card">
                    <div className="front">
                      <h5>
                        15 Admissions <br />
                        (Any-day)*
                      </h5>
                      <p>$185</p>
                    </div>
                    <div className="back">
                      <p>15 plays, any day!</p>
                    </div>
                  </div>
                </div>

                {/* Monthly Pass */}
                {/* <div className="col-lg-3 col-md-6 mt-4 pt-2">
                  <div className="pricing-card">
                    <div className="front">
                      <h5>
                        Monthly Pass <br /> (Any-day)*
                      </h5>
                      <p>$220</p>
                    </div>
                    <div className="back">
                      <p>Unlimited play for a month!</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            {/* Extra Section */}
            <div className="pricing-category">
              <h3 className="category-title">Play Essentials</h3>
              <div className="row pricing-row">
                {/* Shoe Covers */}
                <div className="col-lg-3 col-md-6 mt-4 pt-2">
                  <div className="pricing-card">
                    <div className="front">
                      <h5>Shoe Covers</h5>
                      <p>$1.50</p>
                    </div>
                    <div className="back">
                      <p>Keep it clean with shoe covers.</p>
                    </div>
                  </div>
                </div>

                {/* Socks */}
                <div className="col-lg-3 col-md-6 mt-4 pt-2">
                  <div className="pricing-card">
                    <div className="front">
                      <h5>Socks</h5>
                      <p>$2.50</p>
                    </div>
                    <div className="back">
                      <p>Don't forget your socks!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="asterisk-text-container">
              <p className="asterisk-text">
                *However, Sundays are reserved exclusively for private play and birthday parties.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
